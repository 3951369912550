import React from "react";

const UL = ({ className, customClass, children, data, id }) => (
    <>
    <ul className = {`${className + ' ' + customClass}`}>
    { data && (
    data.map(money => (
        <li className="mb-2" key={id++}>
            {money.amount} million USD in {money.date}
            {conditionalRender(money.from, ' from')}
            {conditionalRender(money.to, ' to')}
            {money.source && (
                <span className="span-source" title={money.source}>
                .&nbsp;
                <a href={money.link} rel="noopener noreferrer" target="_blank">
                    Source
                </a>.
                </span>
            )}
        </li>
    ))
    )}
    {children}
    </ul>
    </>
);
UL.defaultProps = {
  className: 'pl-8 mb-6 leading-normal border-l-4 border-gray-300',
};

export default UL;

function conditionalRender(element, label) {
    if (element) {
        return <>{label} <strong>{element}</strong></>;
    }
    return '';
}
