import React from "react";

const Section = ({ className, children, id }) => (
<section id={id} className={`${className}`}>{children}</section>
);
Section.defaultProps = {
  className: 'mb-12 bg-white shadow-lg rounded p-3 pt-12 xl:p-24',
};

export default Section;
