import React from "react";

const H4 = ({ className, children }) => (
<h4 className={`${className}`}>{children}</h4>
);
H4.defaultProps = {
  className: 'font-bold mb-2',
};

export default H4;
