import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import RankingComponent from "../components/ranking"
import Header from "../components/header"

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Header showChildren={true} />
        <SEO
          description="The Jeffrey Epstein Ranking provides a classification of Universities according to the donations received via the scientific broker Jeffrey Epstein."
          title="Jeffrey Epstein Ranking of University funding"
        />
        <RankingComponent />
      </Layout >
    )
  }
}

export default IndexPage
