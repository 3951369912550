import React from 'react';

import { useStaticQuery, graphql } from "gatsby";
import Main from './ui/main';
import Section from './ui/section';
import H1 from './ui/h1';
import H2 from './ui/h2';
import RankingItems from './ui/rankingItems';
import Mailto from 'react-protected-mailto'

const RankingComponent = () => {
    const rawData = useStaticQuery(graphql`
        query {
            allRankingCsv {
                edges {
                    node {
                        date_pre_2008
                        date_post_2008
                        country
                        university
                        donation_post_2008
                        donation_pre_2008
                        donation_records_post_2008
                        donation_records_pre_2008
                        donor_post_2008
                        donor_pre_2008
                        id
                        link_pre_2008
                        link_post_2008
                        position
                        recipient_post_2008
                        recipient_pre_2008
                        reputation_laundering_post_2008
                        reputation_laundering_pre_2008
                        return_of_funds_pre_2008
                        return_of_funds_post_2008
                        returns_source_pre_2008
                        returns_source_post_2008
                        sources_post_2008
                        sources_pre_2008
                        total_usd
                        type
                    }
                }
            }
        }
    `);

    let data = [];
    let lastPosition = '0';
    let university;
    let emergingUniversities = [];
    let isEmerging = false;

    rawData.allRankingCsv.edges.forEach(row => {
        if (row.node.position === '?') {

            if (university !== undefined) {
                if (isEmerging) {
                    emergingUniversities.push(university);
                } else {
                    data.push(university);
                    isEmerging = true;
                }
            }

            university = {
                id: row.node.id,
                name: row.node.university,
                country: row.node.country,
                type: row.node.type,
                position: row.node.position,
                total_usd: row.node.total_usd,
                pre2008: [],
                post2008: [],
                returnpre2008: row.node.return_of_funds_pre_2008,
                returnpost2008: row.node.return_of_funds_post_2008,
                returnpre2008source: row.node.returns_source_pre_2008,
                returnpost2008source: row.node.returns_source_post_2008,
            };
        } else if (row.node.position !== '' && row.node.position !== lastPosition) {
            if (university !== undefined) {
                data.push(university);
            }
            university = {
                id: row.node.id,
                name: row.node.university,
                country: row.node.country,
                type: row.node.type,
                position: row.node.position,
                total_usd: row.node.total_usd,
                pre2008: [],
                post2008: [],
                returnpre2008: row.node.return_of_funds_pre_2008,
                returnpost2008: row.node.return_of_funds_post_2008,
                returnpre2008source: row.node.returns_source_pre_2008,
                returnpost2008source: row.node.returns_source_post_2008,
            };
        } else {
            if (row.node.donation_pre_2008 !== '') {
                university.pre2008.push({
                    amount: row.node.donation_pre_2008,
                    from: row.node.donor_pre_2008,
                    to: row.node.recipient_pre_2008,
                    date: row.node.date_pre_2008,
                    source: row.node.sources_pre_2008,
                    link: row.node.link_pre_2008,
                    record: row.node.donation_records_pre_2008,
                    reputation: row.node.reputation_laundering_pre_2008,
                });
            }

            if (row.node.donation_post_2008 !== '') {
                university.post2008.push({
                    amount: row.node.donation_post_2008,
                    from: row.node.donor_post_2008,
                    to: row.node.recipient_post_2008,
                    date: row.node.date_post_2008,
                    source: row.node.sources_post_2008,
                    link: row.node.link_post_2008,
                    record: row.node.donation_records_post_2008,
                    reputation: row.node.reputation_laundering_post_2008,
                });
            }
        }
    });

    return (
        <Main>
            <Section id="about">
                <header id="ranking-header" className="font-bold">
                    <H1 customClass="hidden xl:block">
                        JER™<br></br>
                        <span>Jeffrey Epstein Ranking of University funding</span>
                        <br></br>
                        <em>Pecuni(versitas) non olet</em>
                        <br></br>
                        <span className="pt-4 text-sm font-normal text-gray-800">
                            Florencio Cabello - University of Malaga, Spain - <Mailto email="fcabello@uma.es" />
                        </span>
                    </H1>
                    <H1 customClass="xl:hidden">
                        JER™<br></br>
                        <span>Jeffrey Epstein Ranking<br></br>
                            of University funding</span>
                        <br></br>
                        <em>Pecuni(versitas) non olet</em>
                        <br></br>
                        <span className="flex flex-col pt-4 text-xs font-normal leading-normal text-gray-800">
                            Florencio Cabello - University of Malaga, Spain< br ></br>
                            <Mailto email="fcabello@uma.es" />
                        </span>
                    </H1>
                    <H2>About</H2>
                </header>
                <div className="text-base xl:text-lg">
                    <p>
                        Inspired by the clo-academic maxim “Pecuni(versitas) non olet”,
                        the JERTM ranking quantifies the pecunary smell of élit
                        universities funded by Jeffrey Epstein with a view to contributing
                        to the “total bureaucratization” (David Graeber). In sum, the
                        JERTM provide the university and philantropic executives with a
                        classification of higher education entities (American for the most
                        part) according to the donations received via the scientific
                        broker Jeffrey Epstein.
                </p>
                    <p>
                        For more information, see the article "Pecuni(versitas) non olet:
                The Jeffrey Epstein Ranking of university funding" at the <a
                            href="https://transversal.at/blog/the-jeffrey-epstein-ranking-of-university-funding"
                            rel="noopener noreferrer"
                            target="_blank"
                        >Transversal blog</a>.
                </p>
                </div>
            </Section>

            <Section id="ranking">
                <H2>The Ranking</H2>
                <RankingItems position={true} items={data}></RankingItems>
            </Section>

            <Section id="emerging">
                <H2>Emerging JER™</H2>
                <div className="mt-3 mb-10 leading-normal">
                    12 candidates to swell the ranks of the JER™.<br></br>
                    Universities with no specific figures of donations available.
                <br></br>
                    Listed in alphabetical order.
            </div>
                <RankingItems items={emergingUniversities}></RankingItems>
            </Section>
        </Main>
    )
}

export default RankingComponent
