import React from "react";
import H3 from './h3';
import H4 from './h4';
import UL from './ul';

let uniqueFakeShittyId1 = 0;
let uniqueFakeShittyId2 = 0;

const RankingItems = ({ className, items, position }) => (
    <div className="articles-wrapper">
        {items.map(row => (
            <article className="mb-6 xl:mb-12" key={row.id}>
                <header className="">
                    <H3>
                        <span className="inline-flex items-center self-center justify-center w-8 h-8 mr-3 text-xs text-gray-900 bg-gray-300 rounded-full xl:text-xl">
                            {position ? '' + row.position + ' ' : ''}
                        </span>
                        <span className="self-center">{row.name}, {row.country}</span>
                    </H3>
                    <div className="my-6 xl:mt-12 preview">
                        <div><strong>Type:</strong> {row.type}</div>
                        <div><strong>Total:</strong> {row.total_usd} million USD</div>
                    </div>
                </header>
                <div className="content">
                    {row.pre2008.length > 0 && (
                        <>
                            <H4>
                                <strong>Before 2008 conviction:</strong>
                            </H4>
                            <UL data={row.pre2008} id={uniqueFakeShittyId1} />
                        </>
                    )}

                    {row.post2008.length > 0 && (
                        <>
                            <H4>After 2008 conviction:</H4>
                            <UL data={row.post2008} id={uniqueFakeShittyId2} />
                        </>
                    )}

                    <H4>Returned money?</H4>
                    <UL>
                        {row.returnpre2008 && (
                            <li>Before 2008: {row.returnpre2008}
                                {row.returnpre2008source && (
                                    <span className="span-source">
                                        .&nbsp;
                                        <a href={row.returnpre2008source} rel="noopener noreferrer" target="_blank">
                                            Source
                                        </a>.
                                    </span>
                                )}
                            </li>
                        )}
                        {row.returnpost2008 && (
                            <li>After 2008: {row.returnpost2008}
                                {row.returnpost2008source && (
                                    <span className="span-source">
                                        .&nbsp;
                                        <a href={row.returnpost2008source} rel="noopener noreferrer" target="_blank">
                                            Source
                                        </a>.
                                    </span>
                                )}
                            </li>
                        )}
                        {!row.returnpre2008 && !row.returnpost2008 && (
                            <li> Unknown</li>
                        )}
                    </UL>
                </div>
            </article>
        ))}
    </div>
);
RankingItems.defaultProps = {
    className: '',
};

export default RankingItems;

