import React from "react";

const H2 = ({ className, children, id }) => (
    <>
    <h2 id={id} className={`${className}`}>{children}</h2>
    <hr className="my-8 border-b-2 border-gray-300"></hr>
    </>
);
H2.defaultProps = {
  className: 'text-lg xl:text-2xl font-bold leading-tight text-blue',
};

export default H2;
