import React from "react";

const H1 = ({ className, customClass, children }) => (
<h1 className={`${className + ' ' + customClass}`}>{children}</h1>
);
H1.defaultProps = {
  className: 'text-lg xl:text-2xl font-extrabold leading-tight px-0 text-center pb-10 xl:pb-24 pt-6',
};

export default H1;
