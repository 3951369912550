import React from "react";

const H3 = ({ className, children }) => (
<h3 className={`${className}`}>{children}</h3>
);
H3.defaultProps = {
  className: 'flex text-base xl:text-1xl font-bold leading-tight px-0 pt-3',
};

export default H3;
