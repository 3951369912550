import React from "react";

const Main = ({ className, children }) => (
<main role="main" className={`${className}`}>{children}</main>
);
Main.defaultProps = {
  className: 'container w-auto xl:w-large max-w-full mx-3 xl:mx-auto mt-24 mb-12 laptop:mb-24 xl:my-12',
};

export default Main;

